let Account = (function () {

    let _private = {};

    this.initEditAction = () => {
        let form = $('#account-edit');
        if (!form.length) {
            return;
        }
        _private.initCheckBoxValidation(form, 'qualification');
        _private.initUserBase(form);
        _private.initPassword(form);
        _private.initSignaturePin(form);
        if (form.find('input[name=email_verification_mail_send]').pVal()) {
            new EfbHelperDialog({
                isConfirmDialog: true,
                text:            'account.email_change_popup',
                buttons:         [
                    {
                        text:  EfbHelper._('button.ok'),
                        class: 'button text',
                        click: function () {
                            $(this).dialog("close");
                        }
                    },
                ]
            }).show();
        }
        new FormObserver().init(form).setSaveCallBack('saveForm', () => {
            _private.saveAccount(form);
        });
    };

    this.initPasswordAction = () => {
        let form = $('#account-password');
        if (!form.length) {
            return;
        }
        _private.initPassword(form);
        let observer = new FormObserver();
        observer.setSaveButtonSelector(form.find('a.save-button')).init(form)
                .setSaveCallBack('saveForm', () => {
                    _private.saveSetPassword(form);
                });

    };

    /**
     *
     * @param {*} form
     */
    _private.saveSetPassword = form => {
        let self = this;
        $.ajax({
            type:        'post',
            data:        form.serialize(),
            dataType:    'json',
            ajaxElement: $('body'),
            success(response) {
                let formStatus = new FormStatus().init();
                let formParent = $(form).parents('#content');
                if (!_.isUndefined(response.data)) {
                    $(formParent).html(response.data);
                }
                self.initEditAction();
                formStatus.addStatus(response, 'account', '', $('#account-password'));
            }
        });
    };

    /**
     *
     * @param {*} form
     */
    _private.saveAccount = form => {
        let self = this;
        $.ajax({
            type:        'post',
            data:        form.serialize(),
            dataType:    'json',
            ajaxElement: $('body'),
            error(error){
                console.log('error',error);
            },
            success(response) {
                if(response.redirect){
                    return location.href = response.redirect;
                }
                //email_verification_mail_send
                let formParent = form.parents('#content');
                if (!_.isUndefined(response.data)) {
                    $(formParent).html(response.data);
                    form = formParent.find('form');
                }
                self.initEditAction();
                let formStatus = new FormStatus().init();
                formStatus.addStatus(response, 'account', '', $('#account-edit'));
                if (!_.isUndefined(response.errors) && !_.isUndefined(response.errors.password)) {
                    $.each(response.errors.password, (i, key) => {
                        formStatus.addStatus('error', 'account-password', EfbHelper._('error.password.' + key),
                            $(_private.form).find('input[name=password]'));
                    });
                }
            }
        });
    };

    /**
     *
     * @param {*} form
     * @param {string} [checkBoxName]
     */
    _private.initCheckBoxValidation = (form, checkBoxName) => {
        form.find('input[name*="' + checkBoxName + '"]').unbind('click.validateCheckboxGroup')
            .on('click.validateCheckboxGroup', () => {
                    let isValid = form.find('input[name*="' + checkBoxName + '"]:checked').length;
                    if (!isValid) {
                        Validator.addError(form.find('input[name*="' + checkBoxName + '"]').not(':disabled'), Validator.NsIsRequired);
                        return;
                    }
                    Validator.removeError(form.find('input[name*="' + checkBoxName + '"]'), Validator.NsIsRequired);
                }
            );
        form.find('input[name*="' + checkBoxName + '"]').click().click();
    };

    /**
     *
     * @param {*} form
     */
    _private.initUserBase = form => {
        form.find('input[name="email_new"],input[name="username"]')
            .unbind('keyup.validateConfirm').on('keyup.validateConfirm', event => {
                _private.validateUnique($(event.target));
            }
        );
    };

    /**
     *
     * @param {*} form
     */
    _private.initPassword = form => {
        _private.password = {
            rules:  _private.passwordRules,
            inputs: $(),
            hint:   form.find('fieldset.user-password').find('div.hint'),
            hints:  {},
            data:   {
                password:             {e: null, v: ''},
                new_password:         {e: null, v: ''},
                new_password_confirm: {e: null, v: ''},
            }
        };
        _private.password.hint.find('li').each((i, h) => {
            _private.password.hints[$(h).attr('rule')] = $(h);
        })
        _private.passwordInputs = $();
        Object.keys(_private.password.data).forEach(k => {
            let input                   = form.find('input[type=password][name="' + k + '"]');
            _private.password.inputs    = _private.password.inputs.add(input);
            _private.password.data[k].e = input;
            _private.password.data[k].v = input.val();
        })
        _private.password.inputs.unbind('keyup.validatePassword').on('keyup.validatePassword', e => {
            let k                       = $(e.target).attr('name');
            _private.password.data[k].v = _private.password.data[k].e.val();
            _private.validatePassword();
        });
        _private.validatePassword();
    };

    _private.initSignaturePin = form => {
        let pinEl = form.find('input[name="pin"]');
        let hint  = pinEl.parents('fieldset').find('div.hint');
        pinEl.unbind('keypress.prevent').on('keypress.prevent', event => {
            if (!/\d/.test(event.key)) {
                return event.preventDefault();
            }
        }).unbind('keyup.lengthCheck').on('keyup.lengthCheck', event => {
            let length = $(event.target).val().length;
            hint.toggle(!!length);
            hint.find('li').toggleClass('error', length !== 4);
            Validator.validateElement(event);
        })
    };

    /**
     *
     * @param {*} element
     */
    _private.validateRequiredRole = element => {
        let fieldSet = element.parents('fieldset');
        fieldSet.find('input[type=checkbox]').parents('div.formelement').removeClass('error');
        fieldSet.removeAttr('style');
        if (fieldSet.find('input[type=checkbox]:checked').length === 0) {
            fieldSet.find('input[type=checkbox]').parents('div.formelement').addClass('error');
            fieldSet.css('box-shadow', '0 0 0 1px #E60000');
        }
    };

    /**
     *
     * @param {*} element
     */
    _private.validateUnique = element => {
        let key             = element.attr('name');
        let value           = element.val();
        _private.uniqueData = _private.uniqueData || {};
        if (!value || !key || (_private.uniqueData[key] && _private.uniqueData[key] === value)) {
            return;
        }
        _private.uniqueData[key] = value
        const data               = {};
        if (key.indexOf('email') !== -1 && !Validator.validateEmailAddress(value)) {
            return;
        }
        if (key === 'email_new') {
            let email = element.parents('form').find('input[name="email"]').val();
            if (email.toLowerCase() === value.toLocaleString()) {
                return Validator.addError(element, Validator.NsNotUnique);
            }
            key = 'email';
        }
        data[key] = value;
        $.ajax({
            url:         urlHelper.getSimpleUrl('account', 'validate-unique'),
            type:        'get',
            data:        data,
            dataType:    'json',
            ajaxElement: $(),
            success(response) {
                Validator.removeError(element, Validator.NsNotUnique);
                if (!_.isUndefined(response.check[key]) && !!response.check[key] === false) {
                    Validator.addError(element, Validator.NsNotUnique);
                }
            }
        });
    };

    _private.validatePassword = () => {
        const d       = _private.password;
        const isEmpty = Object.keys(_private.password.data).every(k => !_private.password.data[k].v);
        d.hint.find('li').removeClass('success');
        if (isEmpty) {
            d.hint.addClass('v-hidden');
            return d.inputs.parent().removeClass('error validationerror');
        }
        d.hint.removeClass('v-hidden');
        d.inputs.each((i, input) => {Validator.validateIsRequired(input)})
        if (!d.data.new_password.v || d.data.new_password.v !== d.data.new_password_confirm.v) {
            Validator.addError(d.data.new_password_confirm.e, Validator.NsIsRequired);
        } else {
            d.hints.MATCH.addClass('success');
        }
        let hasError = false;
        Object.keys(d.rules).forEach(k => {
            const rule  = d.rules[k];
            let isValid = true;
            if (!_.isUndefined(rule.regex)) {
                isValid = _private.validatePasswordRegex(rule, d.data.new_password.v);
            } else if (!_.isUndefined(rule.min) || !_.isUndefined(rule.max)) {
                isValid = _private.validatePasswordLength(rule, d.data.new_password.v);
            }
            d.hints[k].toggleClass('success', isValid);
            hasError = hasError || !isValid;
        });
        if (hasError) {
            Validator.addError(d.data.new_password.e, Validator.NsIsRequired);
        }
    }

    /**
     *
     * @param {object} rule
     * @param {string} password
     * @returns {boolean}
     */
    _private.validatePasswordRegex = (rule, password) => {
        let mode = 'has';
        if (!_.isUndefined(rule.mode)) {
            mode = rule.mode;
        }
        let compareLength = password.length;
        if (mode === 'has_other') {
            compareLength = 0;
        }
        let after = password.replace(_private.getRegexFromRule(rule), '');
        let res   = compareLength === after.length;
        return mode === 'has_not' ? res : !res;
    };

    _private.getRegexFromRule = rule => {
        const parts = rule.regex.split('/');
        const regex = parts[1];
        const flags = parts.pop().split('');
        if (!flags.includes('g')) {
            flags.push('g');
        }
        return new RegExp(regex, flags.join(''));
    };

    /**
     *
     * @param {object} rule
     * @param {string} password
     * @returns {boolean}
     */
    _private.validatePasswordLength = (rule, password) => {
        if (!_.isUndefined(rule['min'])) {
            return password.length >= rule['min'];
        }
        if (!_.isUndefined(rule['max'])) {
            return password.length <= rule['max'];
        }
        return true;
    };

    this.setPasswordRules = rules => {
        _private.passwordRules = rules;
    };
});
